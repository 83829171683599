import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../_metronic/helpers'
import { useAppSelector } from '../../../hooks'
import { selectAuth } from '../../../features/authSlice'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useAssigntoNewExpenseMutation } from '../../../services/ExpenseApi'
import moment from 'moment'
import { toast } from 'react-toastify'
import { useGetAllExpenseTypeQuery } from '../../../services/GeneralApi'
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import SelectField from '../../../components/textFields/SelectField'
import TextField from '../../../components/textFields/TextField'
import TextAreaField from '../../../components/textFields/TextAreaField'
import { useGetRemaningBudgetQuery } from '../../../services/BudgetApi'
import { constraintConfig } from '../../../constraintConfig'
import SearchSelect from '../../../components/textFields/SearchSelect'
import DateField from '../../../components/textFields/DateField'
type Props = {
    show: boolean
    handleClose: () => void
    selectedExpenseItemIds: any
    refetchDraftItems?: any
}


const AssignToNewExpenseModal = ({ show, handleClose, selectedExpenseItemIds, refetchDraftItems }: Props) => {
    const [newExpense, { data: newExpenseData, isLoading, isSuccess, isError, error }] = useAssigntoNewExpenseMutation()
    const { data: getAllExpenseType } = useGetAllExpenseTypeQuery('')
    const [wordCount, setWordCount] = useState(0);

    const currentDate = new Date();
    const { userAccountID, roleID, expenseType } = useAppSelector(selectAuth);

    const initialValues = {
        expenseId: 0,
        // expenseType: {
        //     id: expenseType === 3 ? 1 : expenseType,
        //     title: expenseType === 1 ? 'Regular' : expenseType === 2 ? 'Flex' : 'Regular'
        // },
        title: '',
        expenseDetail: '',
        reportDate: currentDate.toISOString().split('T')[0],
        expenseStatus: {
            id: 1,
            title: ''
        },
        userAccount: {
            orgUserID: userAccountID,
            firstName: '',
            middleName: '',
            lastName: '',
            email: '',
            imageUrl: ''
        },
        itemIds: [
            0
        ]
    }
    const validationSchema = Yup.object().shape({
        title: Yup.string().required('Report Name is required'),
        reportDate: Yup.string().nullable().required('Reporting Period is required'),
        // expenseType: Yup.object().shape({
        //     id: Yup.number().typeError('Expense Type is required').min(1, 'Expense Type is required').required('Expense type is required'),
        // }),
        expenseDetail: Yup.string()
            .max(500, 'Maximum 500 characters allowed')
            .matches(/^[\s\S]{1,500}$/, 'Maximum 500 characters allowed')
    })

    const assignToNewExpense = async (values: any) => {
        try {
            const response = await newExpense({
                ...values,
                itemIds: selectedExpenseItemIds
            });
            // Handle success if needed
        } catch (err) {
            console.error('API Error:', err);
            // Handle error if needed
        }
    };
    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values, { resetForm }) => {

            assignToNewExpense(values);
        }
    })
    const { data, refetch } = useGetRemaningBudgetQuery({
        userAccountId: userAccountID,
        expenseDate: formik.values.reportDate,
    })
    useEffect(() => {
        refetch()
    }, [])
    const { resetForm } = formik;

    useEffect(() => {
        if (isSuccess) {
            const responseData = (newExpenseData as any)?.message;
            const successMessage = responseData || tosterKey.expenseAddSuccessfully;
            toast.success(successMessage);
            resetForm()
            handleClose();
            refetchDraftItems();
        }
    }, [isSuccess, newExpenseData]);
    useEffect(() => {
        if (isError && error) {
            let errorMessage: string = 'Something went wrong';

            if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
                errorMessage = (error.data as { message: string }).message;
            }
            toast.error(errorMessage);
        }
    }, [isError, error]);

    const handleTextAreaChange = (event: any) => {
        const text = event.target.value;
        const words = text.trim().split("");
        const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
        setWordCount(wordCount);
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-md modal-dialog-centered'
                show={show}
                onHide={handleClose}
                backdrop="static"
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.newExpense}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>

                <form
                    className='form w-100'
                    onSubmit={formik.handleSubmit}
                >
                    <div className='modal-body py-lg-10 px-lg-10'>
                        <div className="row">
                            <div className='col-md-6 mb-5'>
                                <TextField
                                    label={labelKey.reportName}
                                    required={true}
                                    type='text'
                                    placeholder='Enter Name'
                                    {...formik.getFieldProps('title')}
                                    fieldClass={`${formik.touched.title && formik.errors.title ? 'is-invalid' : ''}`}
                                    {...formik.getFieldProps('title')}
                                    value={formik.values.title || ''}
                                />
                                {formik.touched.title && formik.errors.title && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.title}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className='col-md-6 mb-5'>
                                <DateField
                                    label={labelKey.reportingPeriod}
                                    // required={true}
                                    selected={formik.values.reportDate ? new Date(formik.values.reportDate) : null}
                                    onChange={(date) => formik.setFieldValue('reportDate', date)}
                                    max={new Date()} />
                                {formik.touched.reportDate && formik.errors.reportDate && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.reportDate}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                            {/* {roleID !== constraintConfig.roleID.role2 &&
                                <>
                                    {formik.values?.expenseType?.id &&
                                        (formik.values.expenseType.id.toString() === constraintConfig.expenseType.flex.toString()) && (
                                            <div className='col-md-6'>
                                                <label className='d-flex align-items-center fs-5 fw-bold mb-2'>
                                                    {data?.result === 0 ? labelKey.budget : labelKey.remaningBudget}
                                                </label>
                                                <p className='fs-4 fw-bold mb-0 form-control form-control-lg form-control-solid' style={{ border: '1px solid #f0f0f0' }}>
                                                    ${data?.result ? data.result : "0"}
                                                </p>
                                            </div>
                                        )}

                                </>
                            } */}
                            <div className='col-md-12 mb-5'>
                                <TextAreaField
                                    label={labelKey.purposeOfExpense}
                                    rows={5}
                                    placeholder='Enter Description'
                                    {...formik.getFieldProps('expenseDetail')}
                                    fieldClass={`${formik.touched.expenseDetail && formik.errors.expenseDetail ? 'is-invalid' : ''}`}
                                    onChange={(e) => {
                                        formik.handleChange(e);
                                        handleTextAreaChange(e);
                                    }}
                                />
                                <div className='d-flex align-items-center justify-content-between'>
                                    {formik.touched.expenseDetail && formik.errors.expenseDetail && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>
                                                <span role='alert'>{formik.errors.expenseDetail}</span>
                                            </div>
                                        </div>
                                    )}
                                    <p className="text-muted ms-auto mb-0">Characters: (500/{wordCount})</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='modal-footer justify-content-center'>
                        <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                        <button
                            type='submit'
                            className='btn btn-primary mb-5 mt-5'
                            disabled={isLoading}
                        >

                            {!isLoading && <span className='indicator-label'>{labelKey.submit}</span>}
                            {isLoading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    {labelKey.pleaseWait}...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            </Modal>
        </>
    )
}

export default AssignToNewExpenseModal