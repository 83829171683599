import moment from 'moment';
import { useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { KTSVG } from '../../../_metronic/helpers';
import { useGetMileageQuery } from '../../../services/ExpenseApi';
import AddUpdateMileageModal from './AddUpdateMileageModal';
import labelKey from "../../localization/label.json"
import tosterKey from "../../localization/toster.json"
import CurrencyComp from '../../../components/currencyComponent/CurrencyComp';
import TableSettingMenu from '../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../components/textFields/TextField';
import TableHeading from '../../../components/tableHeading/TableHeading';
import { LoadingComponent } from '../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../components/noRecord/NoRecordFound';
import useColumnVisibility from '../../customHooks/useColumnVisibility';
import RefreshComponent from '../../../components/refreshComponent/RefreshComponent';
import { toast } from 'react-toastify';

const MileagePage = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'mileage' });
    const [showAddUpdateClientModal, setShowAddUpdateClientModal] = useState(false)
    const [showClientEdit, setShowClientEdit] = useState({})
    const eidtRoleModalHandler = () => {
        setShowAddUpdateClientModal((prev) => !prev);
    };

    const { data, isFetching: isLoading, refetch, isSuccess } = useGetMileageQuery('')
    const handleRefresh = () => {
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success(tosterKey.dataRefreshedSuccesfully);
                }
            });
    };
    return (
        <>
            <AddUpdateMileageModal show={showAddUpdateClientModal}
                handleClose={() => setShowAddUpdateClientModal(false)} data={showClientEdit} refetch={refetch} />
            <div className={`card`}>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                    <div className='card-title'>
                        <RefreshComponent onClick={handleRefresh} />
                    </div>
                    <div className='card-toolbar ms-auto'>
                        <div className='d-flex align-items-center w-100'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowClientEdit(true);
                                    eidtRoleModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addMileage}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-8 pt-0'>
                    <div className='table-responsive table-height'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold text-muted'>
                                    {hiddenColumns.includes('sr') && (
                                        <TableHeading
                                            label={labelKey.sr}
                                            columnId='sr'
                                            className="ps-3"
                                        />
                                    )}
                                    {hiddenColumns.includes('from') && (
                                        <TableHeading
                                            label={labelKey.from}
                                            columnId='from'
                                        />
                                    )}
                                    {hiddenColumns.includes('to') && (
                                        <TableHeading
                                            label={labelKey.to}
                                            columnId='to'
                                            className="w-200px"
                                        />
                                    )}
                                    {hiddenColumns.includes('ratePerKM') && (
                                        <TableHeading
                                            label={labelKey.ratePerKM}
                                            columnId='ratePerKM'
                                        />
                                    )}
                                    {hiddenColumns.includes('ratePerMi') && (
                                        <TableHeading
                                            label={labelKey.ratePerMi}
                                            columnId='ratePerMi'
                                        />
                                    )}
                                    <th className='text-end rounded-end pe-2 border-0'>
                                        {hiddenColumns.includes('actions') && (
                                            <>
                                                {labelKey.actions}
                                            </>
                                        )}
                                        <TableSettingMenu>
                                            <TextField
                                                rightLabel={labelKey.sr}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("sr")}
                                                checked={hiddenColumns.includes('sr')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.from}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("from")}
                                                checked={hiddenColumns.includes('from')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.to}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("to")}
                                                checked={hiddenColumns.includes('to')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.ratePerKM}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("ratePerKM")}
                                                checked={hiddenColumns.includes('ratePerKM')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.ratePerMi}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("ratePerMi")}
                                                checked={hiddenColumns.includes('ratePerMi')}
                                                fieldClass='mb-4'
                                            />
                                            <TextField
                                                rightLabel={labelKey.actions}
                                                type="checkbox"
                                                onChange={() => handleToggleColumn("actions")}
                                                checked={hiddenColumns.includes('actions')}
                                                fieldClass='mb-4'
                                            />
                                        </TableSettingMenu>
                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {!isLoading && (<>
                                    {data?.result?.length > 0 ?
                                        <>
                                            {data?.result?.map((data: any, index: any) => (
                                                <tr key={index}>
                                                    {hiddenColumns.includes('sr') && (
                                                        <td className='ps-4'>{index + 1}</td>
                                                    )}
                                                    {hiddenColumns.includes('from') && (
                                                        <td>{moment(data.startedDate).format("DD MMM YYYY")}</td>
                                                    )}
                                                    {hiddenColumns.includes('to') && (
                                                        <td>{moment(data.endDate).format("DD MMM YYYY")}</td>
                                                    )}
                                                    {hiddenColumns.includes('ratePerKM') && (
                                                        <td><CurrencyComp amount={data?.ratePerKM} /></td>
                                                    )}
                                                    {hiddenColumns.includes('ratePerMi') && (
                                                        <td><CurrencyComp amount={data?.ratePerMile} /></td>
                                                    )}
                                                    {hiddenColumns.includes('actions') && (
                                                        <td>
                                                            <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                <Tooltip id="update-milage" place="bottom" />
                                                                <div
                                                                    data-tooltip-id="update-milage" data-tooltip-content=' Update Mileage'
                                                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                    onClick={() => {
                                                                        setShowClientEdit(data);
                                                                        eidtRoleModalHandler();
                                                                    }}
                                                                >
                                                                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                </div>
                                                            </div>
                                                        </td>
                                                    )}
                                                </tr>
                                            ))}
                                        </>
                                        :
                                        <tr>
                                            <td colSpan={7}>
                                                <NoRecordFound />
                                            </td>
                                        </tr>
                                    }
                                </>)}
                                {isLoading && (
                                    <tr>
                                        <td colSpan={6} className="text-center">
                                            <LoadingComponent />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                    {/* end::Table container */}
                </div>
                {/* begin::Body */}
            </div>
        </>
    )
}

export default MileagePage