import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import { useGetAllViolationPolicyFieldQuery } from '../../../../services/ExpenseVoilationApi';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import labelKey from '../../../localization/label.json';
import ActiveExpenseVoilationConfirmationModal from './ActiveExpenseVoilationConfirmationModal';
import AddUpdateExpenseVoilation from './AddUpdateExpenseVoilation';
import tosterKey from "../../../localization/toster.json"
import { toast } from 'react-toastify';
import RefreshComponent from '../../../../components/refreshComponent/RefreshComponent';

const ExpenseVoilationPage = () => {
    const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'expenseVoilation' });
    const [pageNumber, setPageNumber] = useState(1)
    const [pageSize, setPageSize] = useState(10);
    const [searchStr, setSearchStr] = useState(''); // debounce search
    const [sortBy, setSortBy] = useState("createDT");
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortedColumn, setSortedColumn] = useState("createDT");
    const [filterByStatus, setFilterByStatus] = useState("");
    const [immediateSearchStr, setImmediateSearchStr] = useState('');
    const [isChecked, setIsChecked] = useState(false)

    const { data, isFetching: isLoading, refetch, isSuccess } = useGetAllViolationPolicyFieldQuery({
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchStr: searchStr,
        sortBy: sortBy,
        sortOrder: sortOrder,
        filterByStatus: filterByStatus
    })
    const [showAddUpdateExpenseViolationModal, setShowAddUpdateExpenseViolationModal] = useState(false)
    const [showDepartmentEdit, setShowDepartmentEdit] = useState({})
    const expenseViolationModalHandler = () => {
        setShowAddUpdateExpenseViolationModal((prev) => !prev);
    };
    const [showExpenseViolationActiveConfirmationModal, setShowExpenseViolationActiveConfirmationModal] = useState(false)
    const expenseViolationActiveConfirmationHandler = () => {
        setShowExpenseViolationActiveConfirmationModal((prev) => !prev);
    };
    const handlePageSizeChange = (e: any) => {
        const newSize = parseInt(e.target.value);
        setPageSize(newSize);
        setPageNumber(1); // Reset page number to 1
    };
    // pagination
    const handlePageClick = (e: any) => {
        setPageNumber(e.selected + 1);
    };
    //per page record
    const handlePageRecords = (e: any) => {
        setPageNumber(1); // Reset the page to 1
    };
    const handleSort = (property: string) => {
        setSortBy(property);
        if (sortedColumn === property) {
            setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
        } else {
            setSortedColumn(property);
            setSortOrder("asc");
        }
        // Perform additional sorting logic...
    };
    const handleSearchChange = (e: any) => {
        const { value } = e.target;
        setImmediateSearchStr(value); // Update immediate search state
        delayedSearch(value); // Update debounced search state
        setPageNumber(1)
    };

    const delayedSearch = useCallback(
        debounce((searchValue) => {
            setSearchStr(searchValue);
        }, 500),
        []
    );
    useEffect(() => {
        if (searchStr === "" ||
            sortOrder === "" ||
            sortBy === "") {
            refetch();
        }
    }, [searchStr, sortOrder, sortBy]);
    const handleRefresh = () => {
        refetch()
            .then(() => {
                if (isSuccess) {
                    toast.success(tosterKey.dataRefreshedSuccesfully);
                }
            });
    };
    return (
        <>
            <AddUpdateExpenseVoilation
                show={showAddUpdateExpenseViolationModal}
                handleClose={() => setShowAddUpdateExpenseViolationModal(false)}
                data={showDepartmentEdit}
                refetch={refetch} />
            <ActiveExpenseVoilationConfirmationModal
                show={showExpenseViolationActiveConfirmationModal}
                handleClose={() => setShowExpenseViolationActiveConfirmationModal(false)}
                data={showDepartmentEdit}
                isChecked={isChecked}
                refetch={refetch}
            />

            <div className='card mb-5'>
                <div className='card-header border-0 pt-6'>
                    <div className='d-flex align-items-center'>
                        <SearchBarComponent
                            placeholder='Expense Violation'
                            value={immediateSearchStr}
                            onChange={handleSearchChange}
                        />
                        <RefreshComponent onClick={handleRefresh} />
                    </div>
                    <div className='d-flex align-items-center ms-auto'>
                        <div className='card-toolbar'>
                            <button className='btn btn-sm btn-light-primary'
                                onClick={() => {
                                    setShowDepartmentEdit(true);
                                    expenseViolationModalHandler();
                                }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                                {labelKey.addExpenseViolation}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='card-body py-8 pt-0'>
                    <div className="row w-100">
                        <div className="col-md-12">
                            <div className='table-responsive table-height'>
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            {hiddenColumns.includes('sr') && (
                                                <TableHeading
                                                    label={labelKey.sr}
                                                    columnId='sr'
                                                    className="ps-4"
                                                />
                                            )}
                                            {hiddenColumns.includes('fieldName') && (
                                                <TableHeading
                                                    label={labelKey.fieldName}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='fieldName'
                                                    className="w-200px"
                                                />
                                            )}
                                            {hiddenColumns.includes('description') && (
                                                <TableHeading
                                                    label={labelKey.description}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='description'
                                                    className="w-400px"
                                                />
                                            )}
                                            {hiddenColumns.includes('violationMessage') && (
                                                <TableHeading
                                                    label={labelKey.violationMessage}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='violationMessage'
                                                    className="w-400px"
                                                />
                                            )}
                                            {hiddenColumns.includes('isActive') && (
                                                <TableHeading
                                                    label={labelKey.status}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='isActive'
                                                />
                                            )}
                                            {hiddenColumns.includes('violationLevelVM') && (
                                                <TableHeading
                                                    label={labelKey.expensePolicyType}
                                                    sortedColumn={sortedColumn}
                                                    sortOrder={sortOrder}
                                                    handleSort={handleSort}
                                                    columnId='violationLevelVM'
                                                />
                                            )}
                                            <th className='text-end rounded-end pe-2 border-0'>
                                                {hiddenColumns.includes('actions') && (
                                                    <>
                                                        {labelKey.actions}
                                                    </>
                                                )}
                                                <TableSettingMenu>
                                                    <TextField
                                                        rightLabel={labelKey.sr}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("sr")}
                                                        checked={hiddenColumns.includes('sr')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.fieldName}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("fieldName")}
                                                        checked={hiddenColumns.includes('fieldName')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.description}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("description")}
                                                        checked={hiddenColumns.includes('description')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.violationMessage}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("violationMessage")}
                                                        checked={hiddenColumns.includes('violationMessage')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.status}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("isActive")}
                                                        checked={hiddenColumns.includes('isActive')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.expensePolicyType}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("violationLevelVM")}
                                                        checked={hiddenColumns.includes('violationLevelVM')}
                                                        fieldClass='mb-4'
                                                    />
                                                    <TextField
                                                        rightLabel={labelKey.actions}
                                                        type="checkbox"
                                                        onChange={() => handleToggleColumn("actions")}
                                                        checked={hiddenColumns.includes('actions')}
                                                        fieldClass='mb-4'
                                                    />
                                                </TableSettingMenu>
                                            </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {!isLoading &&
                                            <>
                                                {data?.result?.data?.length > 0 ? (
                                                    <>
                                                        {data?.result?.data?.map((data: any, index: any) => (
                                                            <tr key={index}>
                                                                {hiddenColumns.includes('sr') && (
                                                                    <td className='ps-4'>{(pageNumber - 1) * pageSize + index + 1}</td>
                                                                )}
                                                                {hiddenColumns.includes('fieldName') && (
                                                                    <td>{data?.fieldName}</td>
                                                                )}
                                                                {hiddenColumns.includes('description') && (
                                                                    <td className='w-400px text-wrap'>
                                                                        {data && data.description && <ReactReadMoreReadLess
                                                                            charLimit={50}
                                                                            readMoreText={"Read more"}
                                                                            readLessText={"Read less"}
                                                                            readMoreClassName="readMore"
                                                                            readLessClassName="readLess"
                                                                        >
                                                                            {data.description}
                                                                        </ReactReadMoreReadLess>}
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('violationMessage') && (
                                                                    <td className='w-400px text-wrap'>
                                                                        {data && data.violationMessage && <ReactReadMoreReadLess
                                                                            charLimit={50}
                                                                            readMoreText={"Read more"}
                                                                            readLessText={"Read less"}
                                                                            readMoreClassName="readMore"
                                                                            readLessClassName="readLess"
                                                                        >
                                                                            {data.violationMessage}
                                                                        </ReactReadMoreReadLess>}
                                                                    </td>
                                                                )}

                                                                {hiddenColumns.includes('isActive') && (
                                                                    <td>
                                                                        <div className='d-flex align-items-center gap-2'>
                                                                            {data?.isActive === true ? <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                                                                <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}
                                                                            <div className="form-check form-switch">
                                                                                <input
                                                                                    className="form-check-input cursor-pointer"
                                                                                    type="checkbox"
                                                                                    role="switch"
                                                                                    id={`flexSwitchCheckChecked-${data?.violationPolicyFieldId}`}
                                                                                    checked={data?.isActive}
                                                                                    onChange={(e) => {
                                                                                        setIsChecked(e.target.checked);
                                                                                        setShowDepartmentEdit(data);
                                                                                        expenseViolationActiveConfirmationHandler();
                                                                                    }}
                                                                                />
                                                                                <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.violationPolicyFieldId}`}></label>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                                {hiddenColumns.includes('violationLevelVM') && (
                                                                    <td>{data?.violationLevelVM?.title}</td>
                                                                )}
                                                                {hiddenColumns.includes('actions') && (
                                                                    <td>
                                                                        <div className='d-flex align-items-center justify-content-end gap-2' >
                                                                            <Tooltip id="update-voilation" place="bottom" />
                                                                            <div
                                                                                data-tooltip-id="update-voilation" data-tooltip-content={labelKey.updateViolation}
                                                                                className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                                                                onClick={() => {
                                                                                    setShowDepartmentEdit(data);
                                                                                    expenseViolationModalHandler();
                                                                                }}
                                                                            >
                                                                                <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                )}
                                                            </tr>
                                                        ))}
                                                    </>) : (
                                                    <tr>
                                                        <td colSpan={7}>
                                                            <NoRecordFound />
                                                        </td>
                                                    </tr>
                                                )}
                                            </>}
                                        {isLoading && (
                                            <tr>
                                                <td colSpan={7} className="text-center">
                                                    <LoadingComponent />
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                            </div>
                            <Pagination
                                totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
                                toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
                                ofResult={data?.result?.totalRecords}
                                onChange={handlePageSizeChange}
                                pageSize={pageSize}
                                onPageChange={handlePageClick}
                                pageCount={data?.result?.totalPages || 0}
                                currentPage={pageNumber}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExpenseVoilationPage