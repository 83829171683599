import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'react-tooltip';
import { KTSVG } from '../../../../_metronic/helpers';
import { LoadingComponent } from '../../../../components/loadingComponent/LoadingComponent';
import NoRecordFound from '../../../../components/noRecord/NoRecordFound';
import Pagination from '../../../../components/pagination/Pagination';
import SearchBarComponent from '../../../../components/searchBar/SearchBarComponent';
import TableHeading from '../../../../components/tableHeading/TableHeading';
import TableSettingMenu from '../../../../components/tableHeading/TableSettingMenu';
import TextField from '../../../../components/textFields/TextField';
import { useGetAllDivisionQuery } from '../../../../services/OrgSettingsApi';
import useColumnVisibility from '../../../customHooks/useColumnVisibility';
import labelKey from '../../../localization/label.json';
import AddUpdateDivisionModal from './AddUpdateDivisionModal';
import DivisionActiveConfirmationModal from './DivisionActiveConfirmationModal';
import RefreshComponent from '../../../../components/refreshComponent/RefreshComponent';
import { toast } from 'react-toastify';
import tosterKey from "../../../localization/toster.json"

const DivisionPage = () => {
  const { hiddenColumns, handleToggleColumn } = useColumnVisibility({ pageName: 'division' });
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10);
  const [searchStr, setSearchStr] = useState(''); // debounce search
  const [sortBy, setSortBy] = useState("createDT");
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortedColumn, setSortedColumn] = useState("createDT");
  const [filterByStatus, setFilterByStatus] = useState("");
  const [immediateSearchStr, setImmediateSearchStr] = useState('');
  const [isChecked, setIsChecked] = useState(false)

  const { data, isFetching: isLoading, refetch, isSuccess } = useGetAllDivisionQuery({
    pageNumber: pageNumber,
    pageSize: pageSize,
    searchStr: searchStr,
    sortBy: sortBy,
    sortOrder: sortOrder,
    filterByStatus: filterByStatus
  })
  const [showAddUpdateDivisionModal, setShowAddUpdateDivisionModal] = useState(false)
  const [showDivisionEdit, setShowDivisionEdit] = useState({})
  const divisionModalHandler = () => {
    setShowAddUpdateDivisionModal((prev) => !prev);
  };
  const [showDivisionActiveConfirmationModal, setShowDivisionActiveConfirmationModal] = useState(false)
  const divisionActiveConfirmationHandler = () => {
    setShowDivisionActiveConfirmationModal((prev) => !prev);
  };
  const handlePageSizeChange = (e: any) => {
    const newSize = parseInt(e.target.value);
    setPageSize(newSize);
    setPageNumber(1); // Reset page number to 1
  };
  // pagination
  const handlePageClick = (e: any) => {
    setPageNumber(e.selected + 1);
  };
  //per page record
  const handlePageRecords = (e: any) => {
    setPageNumber(1); // Reset the page to 1
  };
  const handleSort = (property: string) => {
    setSortBy(property);
    if (sortedColumn === property) {
      setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
    } else {
      setSortedColumn(property);
      setSortOrder("asc");
    }
    // Perform additional sorting logic...
  };
  const handleSearchChange = (e: any) => {
    const { value } = e.target;
    setImmediateSearchStr(value); // Update immediate search state
    delayedSearch(value); // Update debounced search state
    setPageNumber(1);
  };

  const delayedSearch = useCallback(
    debounce((searchValue) => {
      setSearchStr(searchValue);
    }, 500),
    []
  );
  useEffect(() => {
    if (searchStr === "" ||
      sortOrder === "" ||
      sortBy === "") {
      refetch();
    }
  }, [searchStr, sortOrder, sortBy]);
  const handleRefresh = () => {
    refetch()
      .then(() => {
        if (isSuccess) {
          toast.success(tosterKey.dataRefreshedSuccesfully);
        }
      });
  };
  return (
    <>
      <AddUpdateDivisionModal show={showAddUpdateDivisionModal}
        handleClose={() => setShowAddUpdateDivisionModal(false)} data={showDivisionEdit} refetch={refetch} />
      <DivisionActiveConfirmationModal
        show={showDivisionActiveConfirmationModal}
        handleClose={() => setShowDivisionActiveConfirmationModal(false)}
        data={showDivisionEdit}
        isChecked={isChecked}
        refetch={refetch} />

      <div className='card mb-5'>
        <div className='card-header border-0 pt-6'>
          <div className='d-flex align-items-center'>
            <SearchBarComponent
              placeholder='Division'
              value={immediateSearchStr}
              onChange={handleSearchChange}
            />
            <RefreshComponent onClick={handleRefresh} />
          </div>
          <div className='d-flex align-items-center ms-auto'>
            <div className='card-toolbar'>
              <button className='btn btn-sm btn-light-primary'
                onClick={() => {
                  setShowDivisionEdit(true);
                  divisionModalHandler();
                }}
              >
                <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
                {labelKey.addDivision}
              </button>
            </div>
          </div>
        </div>
        <div className='card-body py-8 pt-0'>
          <div className="col-md-12">
            <div className='table-responsive table-height'>
              <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='fw-bold text-muted'>

                    {hiddenColumns.includes('sr') && (
                      <TableHeading
                        label={labelKey.sr}
                        columnId='sr'
                        className="ps-4"
                      />
                    )}
                    {hiddenColumns.includes('divisionName') && (
                      <TableHeading
                        label={labelKey.division}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='divisionName'
                      />
                    )}
                    {hiddenColumns.includes('divisionCode') && (
                      <TableHeading
                        label={labelKey.divisionCode}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='divisionCode'
                      />
                    )}
                    {hiddenColumns.includes('isActive') && (
                      <TableHeading
                        label={labelKey.status}
                        sortedColumn={sortedColumn}
                        sortOrder={sortOrder}
                        handleSort={handleSort}
                        columnId='isActive'
                      />
                    )}
                    <th className='text-end rounded-end pe-2 border-0'>
                      {hiddenColumns.includes('action') && (
                        <>
                          {labelKey.actions}
                        </>
                      )}
                      <TableSettingMenu>
                        <TextField
                          rightLabel={labelKey.sr}
                          type="checkbox"
                          onChange={() => handleToggleColumn("sr")}
                          checked={hiddenColumns.includes('sr')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.division}
                          type="checkbox"
                          onChange={() => handleToggleColumn("divisionName")}
                          checked={hiddenColumns.includes('divisionName')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.divisionCode}
                          type="checkbox"
                          onChange={() => handleToggleColumn("divisionCode")}
                          checked={hiddenColumns.includes('divisionCode')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.status}
                          type="checkbox"
                          onChange={() => handleToggleColumn("isActive")}
                          checked={hiddenColumns.includes('isActive')}
                          fieldClass='mb-4'
                        />
                        <TextField
                          rightLabel={labelKey.action}
                          type="checkbox"
                          onChange={() => handleToggleColumn("action")}
                          checked={hiddenColumns.includes('action')}
                          fieldClass='mb-4'
                        />
                      </TableSettingMenu>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    <>
                      {data?.result?.data?.length > 0 ? (
                        <>
                          {data?.result?.data?.map((data: any, index: any) => (
                            <tr key={index}>
                              {hiddenColumns.includes("sr") && (
                                <td className='ps-4'>
                                  {(pageNumber - 1) * pageSize + index + 1}
                                </td>
                              )}
                              {hiddenColumns.includes("divisionName") && (
                                <td>{data?.divisionName}</td>
                              )}
                              {hiddenColumns.includes("divisionCode") && (
                                <td>{data?.divisionCode}</td>
                              )}
                              {hiddenColumns.includes("isActive") && (
                                <td>
                                  <div className='d-flex align-items-center gap-2'>
                                    {data?.isActive === true ? <span className="badge badge-light-success fs-7 fw-semibold">{labelKey.active}</span> :
                                      <span className="badge badge-light-danger fs-7 fw-semibold">{labelKey.inActive}</span>}
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input cursor-pointer"
                                        type="checkbox"
                                        role="switch"
                                        id={`flexSwitchCheckChecked-${data?.divisionId}`}
                                        checked={data?.isActive}
                                        onChange={(e) => {
                                          setIsChecked(e.target.checked);
                                          setShowDivisionEdit(data);
                                          divisionActiveConfirmationHandler();
                                        }}
                                      />
                                      <label className="form-check-label" htmlFor={`flexSwitchCheckChecked-${data?.divisionId}`}></label>
                                    </div>
                                  </div>
                                </td>
                              )}

                              <td>
                                {hiddenColumns.includes('action') && (
                                  <div className='d-flex align-items-center justify-content-end gap-2' >
                                    <Tooltip id="update-division" place="bottom" />
                                    <div
                                      data-tooltip-id="update-division" data-tooltip-content={labelKey.updateDivision}
                                      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                                      onClick={() => {
                                        setShowDivisionEdit(data);
                                        divisionModalHandler();
                                      }}
                                    >
                                      <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                                    </div>
                                  </div>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>) : (
                        <tr>
                          <td colSpan={7}>
                            <NoRecordFound />
                          </td>
                        </tr>
                      )}
                    </>}
                  {isLoading && (
                    <tr>
                      <td colSpan={7} className="text-center">
                        <LoadingComponent />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <Pagination
              totalResult={data?.result?.totalRecords === 0 ? 0 : ((data?.result?.pageNumber - 1) * data?.result?.pageSize) + 1}
              toResult={data?.result?.totalRecords === 0 ? 0 : Math.min(data?.result?.pageNumber * data?.result?.pageSize, data?.result?.totalRecords)}
              ofResult={data?.result?.totalRecords}
              onChange={handlePageSizeChange}
              pageSize={pageSize}
              onPageChange={handlePageClick}
              pageCount={data?.result?.totalPages || 0}
              currentPage={pageNumber}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default DivisionPage