import React from 'react';
import Select from 'react-select';
import './TextField.css';
import { FaLock } from 'react-icons/fa6';
import { Tooltip } from 'react-tooltip';

interface Option {
    value: string | number;
    label: string;
}

interface Props {
    labelClass?: string;
    leftLabel?: string;
    required?: boolean;
    label?: string;
    selectClass?: string;
    onChange?: (selectedOption: Option | null) => void;
    disabled?: boolean;
    onBlur?: () => void;
    value?: Option | null;
    name?: string;
    options?: Option[];
    placeholder?: string;
    customLabel?: string;
    customLabelClick?: any;
    customLabelClass?: any;
    getOptionLabel?: any
}

const SearchSelect = ({
    labelClass,
    leftLabel,
    required,
    label,
    selectClass,
    onChange,
    disabled,
    name,
    onBlur,
    value,
    options,
    placeholder,
    customLabel,
    customLabelClick,
    customLabelClass,
    getOptionLabel,
    ...fieldProps
}: Props) => {
    // const sortedOptions = options?.slice().sort((a, b) => a.label.localeCompare(b.label));
    const sortedOptions = options?.slice().sort((a, b) => {
        const regex = /^\d+/; // Regex to match leading numbers

        const numA = a.label.match(regex);
        const numB = b.label.match(regex);

        if (numA && numB) {
            // Both labels start with numbers, compare numerically
            return parseInt(numA[0]) - parseInt(numB[0]);
        } else if (numA) {
            // A has a number, B does not -> A should come first
            return -1;
        } else if (numB) {
            // B has a number, A does not -> B should come first
            return 1;
        }

        // If neither label starts with a number, sort alphabetically
        return a.label.localeCompare(b.label);
    });

    return (
        <>
            <Tooltip id={`lock-tooltip`} place="top" />
            <div className={`${leftLabel ? 'd-md-flex w-100 gap-2' : 'w-100'}`}>
                {label &&
                    <div className='d-flex justify-content-between align-items-center'>
                        <label className={`form-label fw-bold text-dark fs-6 text-nowrap ${labelClass}`}>
                            {label}{leftLabel}
                            {required && <span className="required-star text-danger ms-1">*</span>}
                            {disabled &&
                                <>
                                    <FaLock className='fs-7 ms-1' /> <i className="fa fa-info-circle ms-2 cursor-pointer"
                                        data-tooltip-id={`lock-tooltip`}
                                        data-tooltip-content='This field are locked and cannot be modified'></i>
                                </>}
                        </label>
                        <label className={`form-label fw-bold text-primary text-nowrap ${customLabelClass}`} onClick={customLabelClick} style={{ fontSize: '10px' }}>
                            {customLabel}
                        </label>
                    </div>
                }
                <Select
                    className={`${selectClass}`}
                    onChange={onChange}
                    isDisabled={disabled}
                    onBlur={onBlur}
                    value={value}
                    name={name}
                    options={options}
                    // options={sortedOptions}
                    placeholder={placeholder}
                    getOptionLabel={getOptionLabel}
                    {...fieldProps}
                />
            </div>
        </>
    );
};

export default SearchSelect;
