/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from 'axios'
import { useFormik } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { createPortal } from 'react-dom'
import { toast } from 'react-toastify'
import { StepperComponent } from '../../../../_metronic/assets/ts/components'
import { KTSVG } from '../../../../_metronic/helpers'
import { constraintConfig } from '../../../../constraintConfig'
import { selectAuth } from '../../../../features/authSlice'
import { useAppSelector } from '../../../../hooks'
import { useDepartmentsLookupQuery, useGetAllCityQuery, useGetAllCountryQuery, useGetAllDivisionQuery, useGetAllLocationTypeQuery, useGetAllLocationsQuery, useGetAllLst_UserExpenseTypeQuery, useGetAllOrganizationLookUpQuery, useGetAllUserTypeLookupQuery } from '../../../../services/GeneralApi'
import { useGetOrganizationAllRolesQuery } from '../../../../services/OrganizationApi'
import { API_END_POINTS } from '../../../../services/apiEndpoints'
import { useCreateUserMutation, useGetAllManagerQuery } from '../../../../services/authApi'
import labelKey from "../../../localization/label.json"
import tosterKey from "../../../localization/toster.json"
import { Step1 } from './steps/Step1'
import { Step2 } from './steps/Step2'
import { Step3 } from './steps/Step3'
import { Step4 } from './steps/Step4'

type Props = {
  show: boolean
  handleClose: () => void
  data?: any
  refetch?: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const AddUserModal = ({ show, handleClose, data, refetch }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }


  const { userInOrgId, token, baseURL } = useAppSelector(selectAuth);
  const { data: getAllOrganizationLookUp, refetch: orgLookUpRefetch } = useGetAllOrganizationLookUpQuery('');
  const { data: getAllManger, refetch: ManagersRefetch } = useGetAllManagerQuery('');
  const { data: departmentLookup, refetch: departmentRefetch } = useDepartmentsLookupQuery('');
  // const { data: designationLookup } = useDesignationLookupQuery(formik.values.role.roleID);
  const { data: getAllRolesData, refetch: rolesRefetch } = useGetOrganizationAllRolesQuery('');
  const [addContact, { isSuccess: addIsSuccess, isError, error, isLoading: addIsLoading }] = useCreateUserMutation()
  const { data: getAllExpenseType } = useGetAllLst_UserExpenseTypeQuery('')
  const { data: getAllLocationType } = useGetAllLocationTypeQuery('')
  const { data: locationsData, refetch: locationRefetch } = useGetAllLocationsQuery('')
  const { data: getAllCites, refetch: citiesRefetch } = useGetAllCityQuery('')
  const { data: getAllcountries, refetch: countriesRefetch } = useGetAllCountryQuery('')
  const { data: getAlldivision, refetch: divisionRefetch } = useGetAllDivisionQuery('')
  const { data: allUserTypeLookup, refetch: allUserTypeLookupRefetch } = useGetAllUserTypeLookupQuery('')


  useEffect(() => {
    if (show) {
      orgLookUpRefetch()
      ManagersRefetch()
      departmentRefetch()
      locationRefetch()
      rolesRefetch()
      citiesRefetch()
      countriesRefetch()
      divisionRefetch()
      allUserTypeLookupRefetch()
    }
  }, [show, orgLookUpRefetch, ManagersRefetch, departmentRefetch, locationRefetch, rolesRefetch, citiesRefetch, countriesRefetch, divisionRefetch])

  const currentDate = new Date();

  const initialValues = {
    orgId: userInOrgId,
    role: {
      roleID: 0,
      name: ''
    },
    expenseType: {
      id: 0,
      value: ''
    },
    userPermission: [
      {
        permissionId: 0,
        lst_PermissionId: 0,
        key: '',
        value: false
      }
    ],
    userType: {
      id: 1,
      value: ''
    },
    locationType: {
      id: 0,
      value: ''
    },
    department: {
      id: 0,
      title: ''
    },
    designation: {
      id: 0,
      title: ''
    },
    location: {
      id: 0,
      value: ''
    },
    division: {
      id: 0,
      title: ''
    },
    mgrApproveMaxLimit: 0,
    employeeId: '',
    email: '',
    firstName: '',
    middleName: '',
    lastName: '',
    cellPhone: '',
    businessPhone: '',
    businessExt: '',
    active: true,
    managerId: 0,
    managerName: '',
    country: {
      id: 0,
      title: ''
    },
    // city: {
    //   id: 0,
    //   title: ''
    // },
    addressLine1: '',
    addressLine2: '',
    state: '',
    postalCode: '',
    latitude: '',
    longitude: '',
    city: '',
    findAddress: '',
    bcEmployeeId: '',
    reportPermision: false,
    excludeMileageCalculationLogic: false,
    excludeSupportUser: false,
    userTimeZone: {
      id: 0,
      title: ''
    },
    ccFeedFrom: currentDate
  }

  const formik = useFormik({
    initialValues,
    // validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      addContact({ ...values });
      // if (roleID === constraintConfig.roleID.role1 || roleID === constraintConfig.roleID.role2) {
      //   if (String(values.role.roleID) === constraintConfig.roleID.role5.toString() && !values.managerId) {
      //     toast.error(tosterKey.managerIsRequired);
      //   }

      //   else {
      //     addContact({ ...values });
      //   }
      // }
      // else {
      //   addContact({ ...values });
      // }
    },
  })
  const { resetForm } = formik;

  useEffect(() => {
    if (addIsSuccess) {
      toast.success(tosterKey.userCreatedSuccessfully);
      resetForm();
      handleClose();
      refetch();

      // Clear the specific cache storage
      caches.open('api-cache').then((cache) => {
        cache.keys().then((keys) => {
          keys.forEach((request) => {
            cache.delete(request).then((success) => {
              if (success) {
                // console.log(`Cache cleared for: ${request.url}`);
              }
            });
          });
        });
      });
    }
  }, [addIsSuccess]);

  useEffect(() => {
    if (isError && error) {
      let errorMessage: string = 'Something went wrong';

      if ('data' in error && error.data && typeof error.data === 'object' && 'message' in error.data) {
        errorMessage = (error.data as { message: string }).message;
      }
      toast.error(errorMessage);
    }
  }, [isError, error]);

  const [designationLookup, setDesignationLookup] = useState<any>(null);
  // const { data: designationLookup } = useDesignationLookupQuery(formik.values.role.roleID);
  useEffect(() => {
    const fetchData = async () => {
      if (show && formik.values?.role?.roleID) {
        try {
          const headers = {
            Authorization: `Bearer ${token}`,
          };

          const getExpenseLogResponse = await axios.get(
            `${baseURL}/api/General${API_END_POINTS.getAllDesignation}?roleId=${formik.values?.role?.roleID}`,
            {
              headers,
            }
          );
          const designationLookup = getExpenseLogResponse.data;
          setDesignationLookup(designationLookup);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };
    fetchData();
  }, [show, formik.values?.role?.roleID, token]);

  useEffect(() => {
    if (data?.result) {
      const transformedPermissions = data.result.map((permission: any) => ({
        permissionId: 0,
        lst_PermissionId: permission.lst_PermissionId,
        key: permission.key,
        value: false,
      }));

      formik.setValues((prevValues) => ({
        ...prevValues,
        userPermission: transformedPermissions,
      }));
    } else if (!show) {
      formik.resetForm();
    }
  }, [data, show]);

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()
  }

  const nextStep = () => {
    if (!stepper.current) {
      return;
    }
    if (stepper.current.currentStepIndex === 1) {
      // Check if Step 1 fields are valid
      if (!formik.values?.role?.roleID) {
        toast.error(tosterKey.roleIsRequired);
        return;
      }
      else if (!formik.values?.expenseType?.id) {
        toast.error(tosterKey.allowedExpenseTypeIsRequired);
        return;
      }
      else if (!formik.values?.locationType?.id) {
        toast.error(tosterKey.allowedMileageTypeIsRequired);
        return;
      }
      else if (String(formik.values.role.roleID) === constraintConfig.roleID.role5.toString() && !formik.values.managerId) {
        toast.error(tosterKey.managerIsRequired);
        return;
      }
      else if (String(formik.values.role.roleID) === constraintConfig.roleID.role4.toString() && !formik.values.mgrApproveMaxLimit) {
        toast.error(tosterKey.approvalLimitIsRequired);
        return;
      }
      else if (!formik.values?.location?.id) {
        toast.error(tosterKey.locationIsRequired);
        return;
      }
    }

    else if (stepper.current.currentStepIndex === 2) {
      if (!formik.values?.email) {
        toast.error(tosterKey.emailIsRequired);
        return;
      } else if (!/^\S+@\S+\.\S+$/.test(formik.values.email)) {
        toast.error('Invalid email format');
        return;
      } else if (!formik.values?.employeeId) {
        toast.error(tosterKey.employeeIDIsRequired);
        return;
      } else if (formik.values.employeeId === '0') {
        toast.error('Employee ID should be a non-zero ');
        return;
      } else if (formik.values?.employeeId?.length > 25) {
        toast.error('Employee ID should not exceed 25 characters');
        return;
      } else if (formik.values?.bcEmployeeId?.length > 25) {
        toast.error('BC Employee ID should not exceed 25 characters');
        return;
      } else if (!formik.values?.firstName) {
        toast.error(tosterKey.firstNameIsRequired);
        return;
      } else if (!formik.values?.lastName) {
        toast.error(tosterKey.lastNameIsRequired);
        return;
      } else if (!formik.values?.userTimeZone?.id) {
        toast.error(tosterKey.timeZoneIsRequired);
        return;
      }

    }
    else if (stepper.current.currentStepIndex === 3) {
      if (!formik.values?.findAddress) {
        toast.error(tosterKey.findAddress);
        return;
      }
    }

    stepper.current.goNext();
  };

  const handleModalClose = () => {
    resetForm();
    handleClose();
  };
  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog custom-modal-size modal-dialog-centered '
      show={show}
      onHide={handleModalClose}
      onEntered={loadStepper}
      backdrop="static"
    >
      <div className='modal-header'>
        <h2>{labelKey.addExpenseUser}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {/* begin::Step 1*/}
              <div className='stepper-item current' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>1</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.userRole}</h3>
                    <div className='stepper-desc'>{labelKey.addUserRoleDetail}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 1*/}

              {/* begin::Step 2*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>2</span>
                  </div>
                  {/* begin::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.userDetails}</h3>
                    <div className='stepper-desc'>{labelKey.addUserDetail}</div>
                  </div>
                  {/* begin::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 2*/}

              {/* begin::Step 3*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>3</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.userAddress}</h3>

                    <div className='stepper-desc'>{labelKey.addAddressDetail}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}

                {/* begin::Line*/}
                <div className='stepper-line h-40px'></div>
                {/* end::Line*/}
              </div>
              {/* end::Step 3*/}


              {/* begin::Step 5*/}
              <div className='stepper-item' data-kt-stepper-element='nav'>
                {/* begin::Wrapper*/}
                <div className='stepper-wrapper'>
                  {/* begin::Icon*/}
                  <div className='stepper-icon w-40px h-40px'>
                    <i className='stepper-check fas fa-check'></i>
                    <span className='stepper-number'>4</span>
                  </div>
                  {/* end::Icon*/}

                  {/* begin::Label*/}
                  <div className='stepper-label'>
                    <h3 className='stepper-title'>{labelKey.overview}</h3>

                    <div className='stepper-desc'>{labelKey.reviewAndSubmit}</div>
                  </div>
                  {/* end::Label*/}
                </div>
                {/* end::Wrapper*/}
              </div>
              {/* end::Step 5*/}
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/*begin::Form */}
            <form noValidate id='kt_modal_create_app_form' onSubmit={formik.handleSubmit}>
              <Step1 formik={formik}
                getAllOrganizationLookUp={getAllOrganizationLookUp}
                getAllRolesData={getAllRolesData}
                getAllExpenseType={getAllExpenseType}
                getAllManger={getAllManger}
                departmentLookup={departmentLookup}
                designationLookup={designationLookup}
                getAllLocationType={getAllLocationType}
                locationsData={locationsData}
                getAlldivision={getAlldivision}
                allUserTypeLookup={allUserTypeLookup}
              />
              <Step2 formik={formik} />
              <Step3 formik={formik}
                getAllCites={getAllCites}
                getAllcountries={getAllcountries} />
              <Step4 formik={formik}
                getAllRolesData={getAllRolesData} />

              {/*begin::Actions */}
              <div className='d-flex flex-stack pt-10'>
                <div className='me-2'>
                  <button
                    type='button'
                    className='btn btn-lg btn-light-primary me-3'
                    data-kt-stepper-action='previous'
                    onClick={prevStep}
                  >
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr063.svg'
                      className='svg-icon-3 me-1'
                    />{' '}
                    {labelKey.previous}
                  </button>
                </div>
                <div>
                  <button
                    type='submit'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='submit'
                    disabled={addIsLoading}
                  >
                    {!addIsLoading && <span className='indicator-label'>{labelKey.submit} <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-2 me-0'
                    /></span>}
                    {addIsLoading && (
                      <span className='indicator-progress' style={{ display: 'block' }}>
                        {labelKey.pleaseWait}...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                  <button
                    type='button'
                    className='btn btn-lg btn-primary'
                    data-kt-stepper-action='next'
                    onClick={nextStep}
                  >
                    {labelKey.nextStep}{' '}
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr064.svg'
                      className='svg-icon-3 ms-1 me-0'
                    />
                  </button>
                </div>
              </div>
              {/*end::Actions */}
            </form>
            {/*end::Form */}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export { AddUserModal }

