/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import { useEffect, useState } from 'react';
import DateField from '../../../../../components/textFields/DateField';
import TextAreaField from '../../../../../components/textFields/TextAreaField';
import TextField from '../../../../../components/textFields/TextField';
import { constraintConfig } from '../../../../../constraintConfig';
import { selectAuth } from '../../../../../features/authSlice';
import { useAppSelector } from '../../../../../hooks';
import { useGetRemaningBudgetQuery } from '../../../../../services/BudgetApi';
import labelKey from "../../../../localization/label.json";

type Props = {
  formik: any
}

const Step1 = ({ formik }: Props) => {
  const currentDate = moment().format('YYYY-MM-DD')
  const [wordCount, setWordCount] = useState(0);
  const { userAccountID } = useAppSelector(selectAuth)

  const handleTextAreaChange = (event: any) => {
    const text = event.target.value;
    const words = text.trim().split("");
    const wordCount = words.reduce((count: any, word: any) => count + word.length, 0);
    setWordCount(wordCount);
  };
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100' style={{ height: "75vh" }}>

        {/*begin::Form Group */}
        <div className="row m-0">
          <div className='col-md-6 mb-10'>
            <TextField
              label={labelKey.reportName}
              required={true}
              type='text'
              placeholder='Enter Report Name'
              {...formik.getFieldProps('title')}
              value={formik.values.title}
              disabled={formik.values?.isCorpCardReport} />
          </div>
          <div className='col-md-6 mb-10'>
            <DateField
              label={labelKey.reportingPeriod}
              // required={true}
              selected={formik.values.reportDate ? new Date(formik.values.reportDate) : null}
              onChange={(date) => formik.setFieldValue('reportDate', date)}
              max={new Date()}
              disabled={formik.values?.isCorpCardReport} />
          </div>
          <div className='col-md-12 mb-10'>
            <div>
              <TextAreaField
                label={labelKey.purposeOfExpense}
                {...formik.getFieldProps('expenseDetail')}
                cols={20}
                rows={7}
                placeholder='Enter Expense Detail'
                value={formik.values.expenseDetail || ''}
                onChange={(e) => {
                  formik.handleChange(e);
                  handleTextAreaChange(e);
                }}
              />
              <p className="text-muted text-end">Characters: ({wordCount}/500)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step1 };

