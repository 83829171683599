
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../../../../_metronic/helpers';
import TextAreaField from '../../../../components/textFields/TextAreaField';
import TextField from '../../../../components/textFields/TextField';
import labelKey from "../../../localization/label.json";
import DateTimeComp from '../../../../components/dateComponent/DateTimeComp';
import { DataF } from '@react-google-maps/api';
type Props = {
    show: boolean
    handleClose: () => void
    data?: any
}
const RequestLogDetailModal = ({ show, handleClose, data, }: Props) => {
    const handleResultData = (resultData: any) => {
        try {
            // Check if resultData is a string containing exception information
            if (typeof resultData === 'string' && resultData.includes('Exception occur in')) {
                // Extract the input object from the string if it exists
                const match = resultData.match(/Input object:(\{.*\})/);
                if (match && match[1]) {
                    // Parse the JSON object
                    return JSON.stringify(JSON.parse(match[1]), null, 2);
                } else {
                    // Return the entire resultData string if no JSON object is found
                    return resultData;
                }
            }

            // If resultData is an object, directly stringify it
            if (typeof resultData === 'object') {
                return JSON.stringify(resultData, null, 2);
            }

            // If resultData is neither a string nor an object, return it as is
            return resultData;
        } catch (error) {
            console.error('Error parsing resultData:', error);
            return 'Error parsing resultData';
        }
    };
    return (
        <>
            <Modal
                aria-hidden='true'
                dialogClassName='modal-dialog modal-lg modal-dialog-centered'
                show={show}
                onHide={handleClose}
            >
                <div className='modal-header'>
                    <h2 className='mb-0'>{labelKey.requestLogDetail}</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="d-flex align-items-center">
                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                    <div className="symbol-label fs-3 bg-light-info text-info text-capitalize">{data?.userName?.charAt(0)}</div>
                                </div>
                                <div className="d-flex flex-column">
                                    <p className="text-gray-800 fw-bold text-hover-primary mb-1 text-capitalize">{data?.userName}</p>
                                    <span className='text-lowercase'>{data?.email}</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div>
                                <p className='mb-1'><strong>Method Name:</strong> {data.reqMethodName}</p>
                                <p className='mb-1'><strong>Request Timestamp:</strong> <DateTimeComp formattedDate={data.reqDateTime} /></p>
                                <p className='mb-1'><strong>Area:</strong> {data.exceptionArea}</p>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className='mt-10'>
                                <h5>Result Data</h5>
                                <p>
                                    <strong>Input Object:</strong>
                                    {/* <pre className='fs-6 max-h-200px overflow-auto p-5 bg-light rounded-4 mt-2' style={{ maxHeight: '40vh', }}>
                                        {JSON.stringify(
                                            JSON.parse(data.resultData?.match(/Input object:(\{.*\})/)?.[1] || "{}"),
                                            null,
                                            2
                                        )}
                                    </pre> */}
                                    <pre className='fs-6 overflow-x-auto p-5 bg-light rounded-4 mt-2 ' style={{ whiteSpace: 'break-spaces', maxHeight: '40vh' }}>
                                        {handleResultData(data.resultData)}
                                    </pre>
                                </p>
                                <p className='fs-6'><strong>Exception Message:</strong> {data.resultData}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='modal-footer'>
                    <span className='btn btn-light' onClick={handleClose}>{labelKey.cancel}</span>
                </div>
            </Modal>
        </>
    )
}

export default RequestLogDetailModal