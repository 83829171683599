// cacheHelper.js

export const cacheClearHelper = () => {
    // Return a Promise for chaining
    return caches.open('api-cache') // Open the 'api-cache'
      .then((cache) => {
        return cache.keys() // Get all keys (requests) in the cache
          .then((keys) => {
            // Delete each key from the cache
            const deletePromises = keys.map((request) => cache.delete(request));
            return Promise.all(deletePromises); // Wait for all deletions to complete
          });
      })
      .then(() => {
        console.log('Cache cleared successfully');
      })
      .catch((error) => {
        console.error('Error clearing API cache:', error);
      });
  };
  